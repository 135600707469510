import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 1000,
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
}));

export default function ComplexGrid() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Grid container direction="row" alignContent="flex-start" spacing={3}>
                    <Grid item xs>
                        <Typography variant="body1" gutterBottom>Get in touch to discuss your requirements:</Typography>
                        <Typography variant="body1" gutterBottom><a href="mailto:srb@suebeardsmore.co.uk"><strong>srb@suebeardsmore.co.uk</strong></a></Typography>
                        <Typography variant="body1" gutterBottom>Mob: 07736 417606</Typography>
                        <Typography variant="body1" gutterBottom>Tel: 0121 753 2499</Typography>
                    </Grid>
                    <Grid item xs>
                        <Grid container direction="column" alignContent="centre" spacing={3}>
                            <Grid item xs>
                                <img className={classes.img} alt="Sue with glasses" src="/Pic 2.JPG" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}

