import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import SuesVoice from './Sue_Beardsmore-Voice_Demo.mp3';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 1000,
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
}));

export default function ComplexGrid() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Grid container direction="row" alignContent="flex-start" spacing={3}>
                    <Grid item xs>
                        <Grid container direction="column" alignContent="centre" spacing={3}>
                            <Grid item xs>
                                <Typography variant="body2">Sue can provide effective voiceovers -  in a studio or down the line. She has worked on documentaries, 
                                corporate videos, recorded announcements and public service broadcasts. 
                                She has a warm voice with authority and impact that could make all the difference to your production or recorded message.</Typography>
                            </Grid>
                            <Grid item xs>
                                <audio controls>
                                    <source src={SuesVoice} type='audio/mpeg'></source>
                                </audio>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs>
                        <Grid container direction="column" alignContent="centre" spacing={3}>
                            <Grid item xs>
                                <img className={classes.img} alt="Sue with glasses" src="/Sue B pic.JPG" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}

