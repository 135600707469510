import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 1000,
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
}));

export default function ComplexGrid() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Grid container direction="row" alignContent="flex-start" spacing={3}>
                    <Grid item xs>
                        <Typography variant="body2">Sue worked on daily news for BBC television for over 25 years, as a reporter and presenter. 
                        She has extensive production, writing and coaching experience. 
                        She offers tailor-made sessions at a place and time convenient to you – you can sign up for as little as half a day.
                        She works with a wide range of people, from students to corporate clients. 
                        Anyone who needs to get a message across in the best possible way. Sue has a BA in English and Politics, and an MA in Mass Communications. 
                        She’s carried out training assignments in the UK and abroad, working with executives, 
                        press officers and individuals from public service and commercial organisations, arts organisations and charities.</Typography>
                    </Grid>
                    <Grid item xs>
                        <Grid container direction="column" alignContent="centre" spacing={3}>
                            <Grid item xs>
                                <img className={classes.img} alt="Sue with glasses" src="/pic 4.JPG" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}

