import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 1000,
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
}));

export default function ComplexGrid() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Grid container direction="row" alignContent="flex-start" spacing={3}>
                    <Grid item xs>
                        <Typography variant="body2" gutterbottom>Sue Beardsmore is an experienced broadcaster, event host, moderator and trainer. If you need to speak in public, deal with the media, make presentations or sharpen up your broadcasting skills, Sue can help. One to one or group training that explores what you say and how you say it, and really makes a difference. 
                        She is also available to host conferences, events and awards ceremonies – and with over 25 years experience knows what works – and what doesn’t.</Typography>
                        <p></p>
                        <Typography variant="body2">COVID -19 due to the restrictions imposed by the pandemic, Sue is happy to take your event or training into the virtual world!</Typography>
                    </Grid>
                    <Grid item xs>
                        <img className={classes.img} alt="Sue with glasses" src="/pic 3.JPG" />
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}

