import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Home from './Home';
import Training from './Training';
import Voiceover from './Voiceover';
import Corporate from './Corporate';
import Contact from './Contact';
import Header from './Header';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const theme = createMuiTheme({
  palette: {
    background: {
      paper: '#a4a293',
      default: '#ec407a',
    },
    primary: {
      main: 'rgb(255,0,154)',//'#ec407a',
    },
    secondary: {
      main: '#26c6da',
    },
    textPrimary: {
      main: 'rgb(255,0,154)',
    },
    textSecondary: {
      main: '#26c6da',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
        <Header></Header>
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} aria-label="Sue's competencies" variant='fullWidth'>
            <Tab label="Home" {...a11yProps(0)} />
            <Tab label="Training" {...a11yProps(1)} />
            <Tab label="Corporate" {...a11yProps(2)} />
            <Tab label="Voiceover" {...a11yProps(3)} />
            <Tab label="Contact" {...a11yProps(4)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Home></Home>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Training></Training>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Corporate></Corporate>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Voiceover></Voiceover>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Contact></Contact>
        </TabPanel>
      </ThemeProvider>
    </div>
  );
}

