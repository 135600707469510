import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
 //       maxWidth: 1000,
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
}));

export default function ComplexGrid() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Paper className={classes.paper} variant='outlined'>
                <Grid container direction="row" alignContent="flex-start" spacing={3}>
                    <Grid item xs={10}>
                        <Typography variant="h4" color='textPrimary'>
                            Sue Beardsmore
                        </Typography>
                        <Typography variant="h5" color='textSecondary' gutterBottom>
                            Media skills and solutions
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <img className={classes.img} alt="SB" src="/sb-w.jpg" />
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}

