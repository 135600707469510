import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 1000,
    },
    image: {
        width: 128,
        height: 128,
    },
    img: {
        margin: 'auto',
        display: 'block',
        maxWidth: '100%',
        maxHeight: '100%',
    },
}));

export default function ComplexGrid() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Grid container direction="row" alignContent="flex-start" spacing={3}>
                    <Grid item xs>
                        <Typography variant="body2">As a moderator, host or chair, at a venue, or online - 
                        Sue will plan the details of your event with you and make sure that the script, 
                        running order and staging are exactly right for a successful occasion.
                        As a trainer or coach, she’s happy working one to one or in small groups, providing feedback and follow-up support as required.
                        Clients and associates have included: CETA, ACAPP, Black Country Training Group, BMAG, 
                        St Mary’s Hospice, Common Purpose, Lichfield Festival and Writing West Midlands.</Typography>
                    </Grid>
                    <Grid item xs>
                        <Grid container direction="column" alignContent="centre" spacing={3}>
                            <Grid item xs>
                                <img className={classes.img} alt="Sue with glasses smiling" src="/Sue specs smiling.jpg" />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}

